import Request from './../request';
export default {
  //活动列表
  getSpecialActivityPageList: params => {
    let url = '/activity/special/getSpecialActivityPageList'
    return Request.post(url, params);
  },
  //活动列表-审核
  getSpecialActivityAuditPageList: params => {
    let url = '/activity/special/getSpecialActivityAuditPageList'
    return Request.post(url, params);
  },
  //审核操作
  doAudit: params => {
    let url = '/activity/special/audit'
    return Request.post(url, params);
  },
  //喜欢、不喜欢
  doVote: params => {
    let url = '/activity/special-activity-vote/specialActivityVote'
    return Request.post(url, params);
  },
  //求助弹窗文件上传
  uploadFile: params => {
    let url = '/storage/upload/file'
    return Request.post(url, params);
  },
  //救助弹窗参与活动提交
  addSpecialActivity: params => {
    let url = '/activity/special/addSpecialActivity'
    return Request.post(url, params);
  },
  // 霍斯基金-捐赠地址接口
  getFoundationAddress: () => {
    let url = '/system/foundation/getFoundationAddress'
    return Request.get(url);
  },
}
