import Vue from 'vue'
import App from './App.vue'
import Bus from './bus';
import Store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import { API } from './api'
import Storage from './libs/storage.js'
import './../static/style/index.scss'
import { sha256 } from 'js-sha256'
const Base64 = require('js-base64').Base64


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$api = API;
Vue.prototype.$storage = Storage
Vue.prototype.$bus = Bus;
Vue.prototype.$store = Store;
Vue.prototype.$base64 = Base64;
Vue.prototype.$sha256 = sha256

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')