const storage = {
    set(key, value){
      if (typeof value !== 'string') {
        value = JSON.stringify(value)
      }else{
        value = JSON.stringify({
          storagetype:'string',
          content:value
        })
      }
      window.localStorage.setItem(key, value)
    },
    get(key){
      if(!window.localStorage.getItem(key)) return ''
      let value = JSON.parse(window.localStorage.getItem(key))
      if(value.storagetype == 'string'){
        value = value.content
      }
      return value
    },
    remove(key){
      window.localStorage.removeItem(key)
    },
    clear(){
      window.localStorage.clear()
    }
  };
  export default storage;