import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import system from './modules/system';
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    system
  },
  plugins: [
    createPersistedState({ 
        storage: window.sessionStorage,
    })
  ]
})
export default store