import auth from './modules/auth';
import activity from './modules/activity';


export const API = {
  auth,activity
};

export default {
  API
};
