import Request from './../request';
export default {
  //获取首页文字内容
  getProfile: params => {
    let url = '/home/query/profile'
    return Request.get(url);
  },
  //登录、注册
  signInUp: params => {
    let url = '/system/login/operate/signInUp'
    return Request.post(url, params);
  },
  //登出
  logOut: params => {
    let url = '/system/login/operate/logout'
    return Request.post(url, params);
  },
  //获取验证码
  getVerificationCode: params => {
    let url = '/system/login/operate/sendVerificationCode'
    return Request.post(url, params);
  },
  //忘记密码
  forgotPassword: params => {
    let url = '/system/login/operate/forgotPassword'
    return Request.post(url, params);
  },
  


}
