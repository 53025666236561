import Vue from "vue";
import VueRouter from "vue-router"
import Storage from '@/libs/storage.js'
import Bus from '@/bus';
Vue.use(VueRouter)

const Home            = r => require(['@/views/home'], r);
const Examine            = r => require(['@/views/examine'], r);
const Activity            = r => require(['@/views/activity'], r);
const routes=[
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        title: '首页',
        component: Home
    },
    {
        path: '/examine',
        name: 'Examine',
        title: '审核',
        component: Examine
    },
    {
        path: '/activity',
        name: 'Activity',
        title: '活动',
        component: Activity
    },
]

const router = new VueRouter({
    // mode:'history',
    routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
router.beforeEach(async (to, from, next) =>{
    const token = Storage.get('token')
    if(token || to.path == '/home'){
      next()
    }else{
      next('/home')
      Bus.$emit('login-open')
    }
  })
export default router