import Axios from 'axios';
import Storage from '@/libs/storage.js';
import nonce  from '@/libs/nonce';
import Router from '@/router';
import Bus from '@/bus';
Axios.defaults.timeout = 10000;
Axios.interceptors.request.use(
    config => {
        config.headers.nonce=nonce.getNonce() ; 
        config.headers.Authorization = Storage.get('token');
        return config;
    },
    err => {
        return Promise.reject(err);
    });

Axios.interceptors.response.use(
    response => {
        if(response.data.code == '499'){
            localStorage.clear()
            // Router.push('/home')
            Bus.$emit('login-open')
        }
        return response;
    },
    err => {
        return Promise.reject(err);
    });

export default class http {
    post = params => {
        if(params.config){
            return Axios.post(params.api, params.param,params.config);
        }else{
            return Axios.post(params.api, params.param);
        }
    }
    get = params => {
        return Axios.get(params.api, {
            params: params.param
        });
    }
    delete = params => {
        return Axios.delete(params.api, {
            params: params.param
        });
    }
    put = params => {
        return Axios.put(params.api, {
            params: params.param
        });
    }
};