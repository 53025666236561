const nonce ={
    getNonce() {  
        // get-timestamp  
        var timestamp = Date.now();  
        
        // random of 5 numbers.  
        var random = Math.floor(10000 + Math.random() * 90000);  
        
      
        var result = random+ timestamp;  
        
        return result;  
    } 
};

export default nonce;