<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      
    };
  },
  created(){

  },
  mounted(){

  },
  methods:{

  },
}

</script>

<style>

</style>
