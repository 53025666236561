// state
const state = {
  commonText: {},
  resortPopupReady:false
}

// actions
const actions = {}

// getters
const getters = {}

// mutations
const mutations = {
  SET_COMMON_TEXT(state, status){
    state.commonText = status;
  },
  SET_RESORTPOPUP_READY(state, status){
    state.resortPopupReady = status;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}