import Http from "./http";
import { Notification, Message } from 'element-ui';
const Request = new Http();
const axiosConfig_1 = {
  headers:{
    
    
  }
}
const axiosConfig_2 = {
  headers:{'Content-Type': 'multipart/form-dat'}
}
const $baseUrl = '/api'
export default {
  post: async(url,params) => {
    let resData;
    await Request.post({
      api: $baseUrl + url,
      param: params
    }).then(res => {
      if(!res) return
      if(res.data.code == 200){
        resData = res.data;
      }else if(res.data.code == 500){
        Notification.error('服务异常，请稍后再试！');
      }else if(res.data.code == 499){ 
        return
      }
    }).catch(err => {
      Notification.error('服务异常，请稍后再试！');
    })
    return resData;
  },
  get: async (url, params) => {
    let resData;
    await Request.get({
      api: $baseUrl + url,
      param: params
    }).then(res => {
      if(!res) return
      if(res.data.code == 200){
        resData = res.data;
      }else if(res.data.code == 500){
        Notification.error('服务异常，请稍后再试！');
      }else if(res.data.code == 499){ 
        return
      }
    }).catch(err => {
      Notification.error('服务异常，请稍后再试！');
    })
    return resData;
  }
}
